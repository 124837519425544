import React, {Fragment, useEffect, useReducer, useState} from 'react';
import AppLocationMainBlock from "./app.location.main.block";
import AppLocationFormModal from "./app.location.form.modal";
import {gql, useLazyQuery, useQuery} from "@apollo/client";


const AppLocationMain = (props) => {

    let storageCity = undefined;
    try{
        storageCity = JSON.parse(localStorage.getItem('userCity'));
    }
    catch(e){
        storageCity = undefined;
    }


    if(!storageCity){

        // показывать запрос города
        // по умолчанию Москва
        storageCity = {id:605,caption:"Москва",source:'default'};
        // возможность поиска по местоположению
    }


    const [isOpenLocationModal, setIsOpenLocationModal] = useState(false)
    const [location, setLocation] = useState(storageCity);



    const showLocationModal = () => {
        setIsOpenLocationModal(true);
    }

    const toggleLocationModal = () => {
        setIsOpenLocationModal(!isOpenLocationModal);
    }


    return (
        <div>
            <AppLocationMainBlock extraId={props.id} canShowGuess={props.canShowGuess} city={location} setLocation={setLocation} showLocationModal={showLocationModal} />
            <AppLocationFormModal
                show={isOpenLocationModal}
                toggle={toggleLocationModal}
                setLocation={setLocation}
                defaultValue={location}
            />
        </div>
    );
}

export default AppLocationMain;
