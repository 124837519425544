import React, { useState } from 'react';
import { createRoot } from 'react-dom/client';
import AppLocationMain from './app.location.main';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './auth/app.auth.context';
import { clientPrivate } from '../config/apollo_client';
import { ApolloProvider } from '@apollo/client';
import Main from './header/navButtons/components/main';

let hdr = {};

/*
const client = new ApolloClient({
    uri: '/front2api/',
    cache: new InMemoryCache(),
    credentials: 'same-origin',
    headers: hdr
});
*/

const AppLocation = (props) => {
    const [ visible, setVisible ] = useState(false);

    if (props.id === 'top' && !visible) {
        setVisible(true);
    }

    return (
        <ApolloProvider client={clientPrivate}>
            <AuthProvider>
                <BrowserRouter>
                    <Routes>
                        <Route path="/*" element={<AppLocationMain canShowGuess={visible}/>}/>
                    </Routes>
                </BrowserRouter>
            </AuthProvider>
        </ApolloProvider>
    );
};

const AppLocationTop = (
      <AppLocation id="top"/>
    );

const AppLocationSide = (
      <AppLocation id="side"/>
    );



const elTop = document.getElementById('locationButton');
if(elTop){
    const root = createRoot(elTop);
    root.render(AppLocationTop);
}


// рендер для выезжающей панели для маленького экрана
const elSide = document.getElementById('locationButtonSide');
if(elSide){
    const rootSide = createRoot(elSide);
    rootSide.render(AppLocationSide);
}
