import React, {Fragment, useEffect, useRef, useState} from 'react';
import Nav from "react-bootstrap/Nav";
import {OverlayTrigger, Popover} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {Link, useLocation} from "react-router-dom";
import getDocumentElement from "@popperjs/core/lib/dom-utils/getDocumentElement";
import {checkUser} from "./auth/app.auth.actions";
import {useAuthDispatch} from "./auth/app.auth.context";

const AppLocationMainBlock = (props) => {


    let ref = useRef();
    let defaultShowPopover = (props?.city?.source === 'default');
    const [showPopover,setShowPopover] = useState(false);
    const [target, setTarget] = useState(null);

    let locationURL = useLocation();
    let showLocation = true;
    if(locationURL){
        if(locationURL.pathname.includes('orders')){
            showLocation = false;
        }
    }
    console.log('location',showLocation);
    //console.log('COMPONENT POPOVER');
    //console.log('showPopover',showPopover);
    //console.log('defaultShowPopover',defaultShowPopover);
    //console.log('canShowGuess',props.canShowGuess);

    //console.log('ref');

    //console.log(ref);

    let canShow = false;

    const dispatch = useAuthDispatch();

    const checkAuth = async () => {
        await checkUser(dispatch);
    }


    useEffect( () => {
        /*
        console.log('locationMAIN');
        console.log(ref?.current);
        console.log('****************')
        */

        // отобразить поповер после рендера элемента
        // если его отобразить при рендере - он лезет под футер всей страницы
        //console.log("*****************");
        //console.log('AppLocationMainBlock, useEFFECT location,  defaultShowPopover: ',defaultShowPopover, 'showPopover: ',showPopover);
        //console.log('*****************');



        if(defaultShowPopover !== showPopover){
            if(!showPopover){
                if(props.canShowGuess){
                    setShowPopover(true);
                    setTarget(ref);
                }
                //setShowPopover(defaultShowPopover);
            }
        }
    },[props.canShowGuess])

    const commitLocation = () => {
        let newCity = {id:props?.city?.id, caption:props?.city?.caption, source:'user-from-suggest'};
        props.setLocation(newCity);
        setShowPopover(false);
        localStorage.setItem('userCity',JSON.stringify(newCity));
    }

    const chooseOtherCity = () => {
        props.showLocationModal();
        setShowPopover(false);
    }

    // колбек вызывается каждый раз когда поповер хочет изменить свое состояние (показаться или скрыться)
    const overlayToggle = (isShowing) => {
        
        // не мешаем закрыться поповеру если он того желает
        if(showLocation){
            // здесь можно показывать и скрывать поповер
            setShowPopover(isShowing);
        }else{
            // здесь резрешаем только закрыться
            if(!isShowing){
                setShowPopover(isShowing);
            }
        }
        
    }

    return (
        <>
        {showLocation && (
        <OverlayTrigger
            show={showPopover}
            onToggle={overlayToggle}
            target={target}
            trigger={[]}
            placement="bottom"
            rootClose={true}
            rootCloseEvent = 'mousedown'
            overlay={
                <Popover id={"guess-city-popover-bottom-" + props.extraId }className="shadow-lg" >
                    <Popover.Body>
                        <div className="fs-4 text-nowrap mb-2">Ваш город {props.city.caption}?</div>
                        <div className="d-flex">
                            <Button variant="dark" id="confirmLocation" className="px-4 me-2" onClick={commitLocation}>Да</Button>
                            <Button variant="link" onClick={chooseOtherCity} className="text-nowrap text-muted">Выбрать другой город</Button>
                        </div>
                    </Popover.Body>
                </Popover>
            }
        >
            <Nav.Link name="locationNav"
                      ref={ref}
                      href="#"
                      onClick={props.showLocationModal}
                      className="nav-item d-flex d-md-inline-flex align-items-center px-md-0 fs-md-6 text-dark"
            >
                <svg className="d-none d-md-inline-block align-top me-1" width="20" height="20">
                    <use xlinkHref="#icon-location-fill"/>
                </svg>
                <svg className="d-inline-block d-md-none align-top me-3" width="24" height="24">
                    <use xlinkHref="#icon-location"/>
                </svg>
                <span>{props.city.caption}</span>
            </Nav.Link>
        </OverlayTrigger> )}
        {/* распорка, что бы ничего не прыгало в шапке*/  
            !showLocation && (
            <div style={{marginTop:"8px", marginBottom:"8px"}}>
                <svg className="d-none d-md-inline-block align-top me-1 opacity-0" width="20" height="20">
                    <use xlinkHref="#icon-location-fill"/>
                </svg>
                <svg className="d-inline-block d-md-none align-top me-3 opacity-0" width="24" height="24">
                    <use xlinkHref="#icon-location"/>
                </svg>
                <span>&nbsp;</span>
            </div>
        )}
        </>
    )
}

export default AppLocationMainBlock;
