import React from 'react';
import Modal from 'react-bootstrap/Modal';
import ModalBody from "react-bootstrap/ModalBody";
import {MpCitySelect} from "../components/mp.citySelect";

const AppLocationFormModal = (props) => {

    const modalLocationHide = () => {
        if(props.toggle){
            props.toggle();
            return true;
        }else {
            return false;
        }
    }


    // сохранение города
    function locationSubmit(inputValue) {
        // e.preventDefault();
        if (inputValue.id && inputValue.id !== props.defaultValue.id) {
            let newValue = {
                id: inputValue.id,
                caption: inputValue.name
            }
            props.setLocation(newValue);
            //localStorage.getItem('userCity')
            localStorage.setItem('userCity',JSON.stringify(newValue));
            props.toggle();
        }



    }

    // const [storageCity, setStorageCity] = useState({id:605,caption:'Москва',name:'Москва',source:'default-value'});

    return (
        <Modal show={props.show} autoFocus={false} id="modalLocation" backdrop={true} keyboard onHide={modalLocationHide}>
            <ModalBody className="p-6 p-md-10">
                <button type="button" className="btn-close position-absolute z-1 top-0 end-0 m-4"
                        data-bs-dismiss="modal" aria-label="Close" onClick={modalLocationHide}>
                </button>
                <form onSubmit={locationSubmit}>
                    <div className="formTitle d-flex flex-column align-items-center">
                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M31.4331 33.1945C34.2553 28.6767 36 23.414 36 20C36 13.3726 30.6274 8 24 8C17.3726 8 12 13.3726 12 20C12 23.4632 13.6639 28.7417 16.4105 33.2237C17.7567 35.4204 19.2386 37.216 20.683 38.4175C22.1561 39.6428 23.2768 40 24 40C24.5557 40 25.6075 39.6909 27.1031 38.4322C28.547 37.2171 30.0535 35.4029 31.4331 33.1945ZM24 44C31.5348 44 40 28.8366 40 20C40 11.1634 32.8366 4 24 4C15.1634 4 8 11.1634 8 20C8 28.8366 16 44 24 44Z" fill="#262626"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M24 24C26.2091 24 28 22.2091 28 20C28 17.7909 26.2091 16 24 16C21.7909 16 20 17.7909 20 20C20 22.2091 21.7909 24 24 24ZM24 26C27.3137 26 30 23.3137 30 20C30 16.6863 27.3137 14 24 14C20.6863 14 18 16.6863 18 20C18 23.3137 20.6863 26 24 26Z" fill="#262626"/>
                        </svg>
                        <h4 className="fs-2 mb-4 mt-4">Ваш город</h4>
                    </div>

                    <div className="">
                        <MpCitySelect
                            storageCity={props.defaultValue}
                            setStorageCity={locationSubmit}
                            // cityRef={props.cityRef}
                        />
                    </div>

                    {/*<button disabled={isDisabled} type="submit" className="btn btn-lg btn-dark w-100">Сохранить</button>*/}
                </form>
            </ModalBody>
        </Modal>
    )
}

export default AppLocationFormModal;
